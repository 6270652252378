<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item :label="$t('AppManage.Appdetection.Namedetail.SelectApp')" prop="appName">
        <el-input :disabled="true" :placeholder="$t('AppManage.Appdetection.Namedetail.PlaceSelect')" size="small" v-model="ruleForm.appName" style="width: 215px">
        </el-input>
        <el-upload action="#" :auto-upload="false" :show-file-list="false" :on-success="handleAvatarSuccess1"
          class="el-button mar-class-8" style="padding: 0; margin-left: 12px; border: none" :on-change="changeUpload"
          multiple accept=".apk">
          <el-button size="small" style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{ $t('AppManage.Appdetection.Namedetail.SelectApplication') }}</span>
          </el-button>
        </el-upload>
        <el-progress v-if="flagStatus" :percentage="percentage" :color="customColorMethod" style="width: 230px">
        </el-progress>
      </el-form-item>
      <!-- <el-form-item label="应用类别：" prop="appcategoryid">
        <el-select
          size="small"
          style="width: 215px"
          v-model="ruleForm.appcategoryid"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="建设单位：" prop="department">
        <el-input size="small" style="width:215px;" placeholder="请输入建设单位" v-model="ruleForm.department"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="安装类型：">
        <el-checkbox v-model="ruleForm.appType"
          ><span style="color: #606266">必装应用</span></el-checkbox
        >
      </el-form-item>
      <el-form-item label="应用简介：">
        <el-input
          type="textarea"
          v-model="ruleForm.memo"
          style="width: 215px"
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button size="small" :loading="logdingFlag" :disabled="disabledFlag" @click="submitForm('ruleForm')" type="primary">{{$t('public.Save')}}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },
  props: {
    androidData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        this.logdingFlag = false
        callback(new Error("名称不支持特殊字符"));
      } else {
        callback();
      }
    }
    return {
      purgeStatus:0,
      parseStatus:0,
      logdingFlag: false,
      disabledFlag: false,
      percentage: 0,
      flagStatus: false,
      titleInfo: "保存成功",
      visibaelFlag: false,
      checked: true,
      queryObj: {
        manage: 1,
        selectcont: "",
      },
      options: [],
      ruleForm: {
        appName: "", //
        memo: "",
        appcategoryid: "",
        osType: "20",
        appType: "",
        name: '',
        packageName:'',
        versionName:''
      },
      rules: {
        appName: [
          { required: true, message: "请选择应用", trigger: "blur" },
          { validator: validateInput, trigger: ["blur", "change"] }
          // {
          //   required: true,
          //   pattern:
          //     /[`~!@#$%^&*()\-+=<>?:"{}|,\/;'\\[\]~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im,
          //   message: "名称不支持特殊字符",
          //   trigger: "blur",
          // },
        ],
        appcategoryid: [
          { required: true, message: "请选择应用类别", trigger: "change" },
        ],
      },
      path: "",
    };
  },
  created() {
    this.selectData();
    if (this.androidData) {
      this.ruleForm = this.androidData;
      this.purgeStatus = this.androidData.purgeStatus;
      console.log(this.androidData.apptype)
      this.$set(this.ruleForm, 'appType', this.androidData.apptype)
    }
  },
  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "/\ |\/|\~|\!|\@|\#|\\$|\%|\^|\&|\*|\(|\)|\+|\{|\}|\:|\<|\>|\?|\[|\]|\,|\/|\;|\'|\`|\=|\\\|\|/"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1);
      return num;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    handleClose() {
      this.visibaelFlag = false;
    },
    async saveSubmit() {
      this.logdingFlag = true
      if (this.ruleForm.id) {
        let params = {
          id: this.ruleForm.id + '',
          // appCategoryId: this.ruleForm.appcategoryid + "",
          appCategoryId: "1",
          appType: this.ruleForm.appType ? 2 : 1,
          department: this.ruleForm.department,
          memo: this.ruleForm.memo,
          parseStatus:this.parseStatus,
          purgeStatus: this.purgeStatus,
        };
        const res = await this.$axios.post(
          "/httpServe/entApp/update",
          params,
          true
        );
        if (res.code == 200) {
          this.logdingFlag = false
          this.$emit("haldesotr");
        }
      } else {
        let params = {
          appName: this.ruleForm.appName,
          path: this.path,
          // appCategoryId: this.ruleForm.appcategoryid,
          appCategoryId: "1",
          appType: this.ruleForm.appType ? 2 : 1,
          department: this.ruleForm.department,
          memo: this.ruleForm.memo,
          parseStatus:this.parseStatus,
          name: this.ruleForm.name,
          packageName:this.ruleForm.packageName,
          versionName:this.ruleForm.versionName,
          osType: "20",
          purgeStatus: this.purgeStatus,
        };
        // console.log(params)
        const res = await this.$axios.post(
          "/httpServe/entApp/save",
          params,
          true
        );
        if (res.data == 0) {
          this.logdingFlag = false
          this.$message({
            message: this.$t('AppManage.Appdetection.Namedetail.Uploadappname'),
            type: 'error',
            offset: 100
          })
          this.ruleForm = {};
        }
        if (res.data >= 1) {
          this.$refs.ruleForm.resetFields();
          this.ruleForm.memo = "";
          this.ruleForm.appType = false;
          this.logdingFlag = false
          this.$emit("haldesotr");
        }
      }
    },
    async selectData() {
      // 应用类别----下拉框接口
      var param = {
        currentPage: 1,
        pageSize: 999999,
        appCategory: '',
        // orderColume: '',
        // orderRule: ''
      };
      const res = await this.$axios.post(
        "/httpServe/entAppCategory/getDataInfo",
        param,
        true
      );
      // console.log(res)
      this.options = res.data.content;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    //文件上传接口
    async changeUpload(file) {
      console.log(file.progress);
      this.percentage = 0;
      this.flagStatus = true;
      this.disabledFlag = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message({
            message: this.$t('AppManage.Appdetection.Namedetail.Cannotbelarge'),
            type: 'error',
            offset: 100
          })
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["apk"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('AppManage.Appdetection.Namedetail.FileFormatError'),
          type: "error",
          duration: 2000,
        });
      } else {
        if (file.size / (1024 * 1024) > 70) {
          //大于100m
          let timer = setInterval(() => {
            this.percentage += this.mathdom();
            if (this.percentage >= 90) {
              this.percentage = 95;
              clearInterval(timer);
            }
          }, 1500);
        }
        if (file.size / (1024 * 1024) < 70) {
          //判断文件小于50m
          let timer = setInterval(() => {
            this.percentage += this.mathdom();
            if (this.percentage >= 90) {
              this.percentage = 95;
              clearInterval(timer);
            }
          }, 100);
        }

        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "apk");
        form.append("moduleName", "app");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );

        this.path = res.data.data[0];
        console.log(this.path);
        if (this.path) {
          // this.titleInfo = "上传应用成功";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('AppManage.Appdetection.Namedetail.Successfullyupload'),
            type: 'success',
            offset: 100
          })
          this.parseAppProgress()
          this.logdingFlag = false
          this.flagStatus = false;
        } else {
          // this.titleInfo = "上传应用失败";
          // this.visibaelFlag = true;
          this.$message({
            message: this.$t('AppManage.Appdetection.Namedetail.Failedupload'),
            type: 'error',
            offset: 100
          })
          this.flagStatus = false;
        }
        // console.log(file.name)
        this.$nextTick(() => {
          // this.ruleForm.appName = ;
          this.$set(this.ruleForm, "appName", file.name);
        });
      }
    },
    async parseAppProgress() {
      let params = {
        path:this.path
      }
      const res = await this.$axios.post(
        '/httpServe/entApp/parseApp',
        params,
        true
      )
      if(res.data.parseStatus == 0) {
        this.logdingFlag = false
        this.$set(this.ruleForm,'name',res.data.name)
        this.$set(this.ruleForm,'packageName',res.data.packagename)
        this.$set(this.ruleForm,'versionName',res.data.versionname)
      }
      if(res.data.parseStatus == 1) {
        this.$message({
            message: this.$t('AppManage.Appdetection.Namedetail.Parsingapp'),
            type: 'error',
            offset: 100
          })
      }
      this.disabledFlag = false;
      this.purgeStatus = res.data.purgeStatus;
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    handleAvatarSuccess1() { },
  },
};
</script>

<style lang="scss" scoped>
.el-textarea__inner {
  font-size: 14px !important;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>