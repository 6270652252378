<template>
  <div>
    <el-form :model="dictData" ref="dictData" label-width="150px" class="demo-ruleForm">
      <el-form-item :label="$t('public.ApplicationName')+'：'" prop="appName">
        {{ dictData.appName }}
      </el-form-item>
      <el-form-item :label="$t('public.OperatingSystem')+'：'">
        <template v-if="dictData.osTypeId == 20"> Android </template>
        <template v-if="dictData.osTypeId == 10"> iOS </template>
      </el-form-item>
      <el-form-item :label="$t('public.Size')+'：'" prop="appSizeStr">
        {{ dictData.appSizeStr }}
      </el-form-item>
      <el-form-item :label="$t('public.Version')+'：'" prop="versionName">
        {{ dictData.versionName }}
      </el-form-item>
      <!-- <div class="diailog-footer">
        <el-button
          size="small"
          type="primary"
          class="close"
          @click="handleClose"
          >关闭</el-button
        >
      </div> -->
      <!-- <el-button  size="small" type="primary" class="btn-cancel" @click="cancel">取消</el-button> -->
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },

  methods: {
    handleClose() {
      this.$emit("Close");
    },
  },

  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
.demo-ruleForm {
  margin-left: 90px;
}
.diailog-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
// .el-form-item {
//   margin-bottom: 0;
//   font-size: 12px;
// }
// .el-form-item__content {
//   font-size: 12px;
// }
// .demo-ruleForm {
//   margin-left: 90px;
// }
// .diailog-footer {
//   width: 100%;
//   position: absolute;
//   bottom: 0px;
//   right: 0px;
//   padding: 5px 20px !important;
//   background-color: #f5f5f5;
//   border-top: 1px solid #ddd;
//   border-radius: 0 0 6px 6px;
// }
// .close {
//   float: right;
// }
// .demo-ruleForm {
//   font-size: 12px;
// }
</style>